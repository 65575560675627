.nav__logo {
  line-height: 75px;
  font-weight: 700;
  font-size: 24px;
  text-decoration: none !important;
  color: #111456d9;
  cursor: pointer;
}

.nav__logo_color {
  color: #3bb6b5d9;
}

.nav__mobile {
  display: flex;
  flex-grow: 1;
  line-height: 75px;
}

.nav__mobile-menu-icon {
  margin-left: auto;
}

.nav__mobile-menu-icon:hover {
  cursor: pointer;
}

.nav__mobile-menu-icon-label {
  display: inline-block;
  text-decoration: none;
  margin-right: 10px;
}

.nav__mobile-menu-icon-label:hover {
  text-decoration: underline;
  cursor: pointer;
}

.nav__mobile-menu {
  background: #fff;
  background-image: none;
}

.nav__mobile-menu-closed {
  display: none;
}

.nav__mobile-menu-open {
  display: block;
}

.menu__items {
}

.menu__items ul {
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: block;
}

.menu__items ul li {
  list-style-type: none;
  line-height: 50px;
  color: #29343e;
  border-bottom: 1px solid #eceff1;
}
