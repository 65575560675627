/*
  DESKTOP NAVIGATION
*/

.nav__container {
  display: flex;
}

.nav__logo {
  line-height: 75px;
  font-weight: 700;
  font-size: 24px;
  text-decoration: none !important;
  color: #111456d9;
  cursor: pointer;
}

.nav__logo_color {
  color: #3bb6b5d9;
}

.nav {
  margin-left: 25px;
  display: flex;
  flex-grow: 1;
}

.nav ul {
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-grow: 1;
}

.nav ul li {
  display: flex;
  height: 100%;
  line-height: 75px;
  margin-left: 30px;
}

.nav ul li {
  color: inherit;
  text-decoration: none;
}

.nav ul li:hover {
  text-decoration: underline;
  cursor: pointer;
}

.nav .callback {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.nav .callback .callback__container {
  cursor: pointer;
  height: fit-content;
  padding: 10px 20px;
  background-color: orange;
  border-radius: 8px;
}

.nav .callback .callback__container a {
  color: #fff;
  text-decoration: none;
}

.nav__submenu {
  background: #f4f7fb;
  padding: 20px;
  display: none;
  position: absolute;
  left: 0;
  top: 75px;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0 6px 10px -2px rgb(0 0 0 / 20%);
  box-shadow: 0 6px 10px -2px rgb(0 0 0 / 20%);
  border: 1px solid rgb(0 0 0 / 20%);
  cursor: default;
}

.nav ul li:hover .nav__submenu {
  display: block;
}

@media (min-width: 992px) {
  .nav ul li {
    margin-left: 100px;
  }
}
